import { template as template_902e7eda3a7046c890943ccb345d3b07 } from "@ember/template-compiler";
const SidebarSectionMessage = template_902e7eda3a7046c890943ccb345d3b07(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
