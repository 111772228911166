import { template as template_1442a0e819b04ffdaa3a33da03e87b73 } from "@ember/template-compiler";
const WelcomeHeader = template_1442a0e819b04ffdaa3a33da03e87b73(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
