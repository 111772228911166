import { template as template_c1202f6f1e604b17a204c6e90ada7c3c } from "@ember/template-compiler";
const FKText = template_c1202f6f1e604b17a204c6e90ada7c3c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
